import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row, Container } from "reactstrap";
import { Link, Element } from "react-scroll";
import { ThemeProps } from "@/shared/prop-types/ReducerProps";
import Modal from "@/shared/components/Modal";
import Logo from "@/assets/Images/SignPunsLogo.svg";
import Header from "./components/Header";
import Footer from "./components/Footer";

const Landing = () => {
  const [isAuthorized, setIsAuthorized] = useState(true);
  return (
    <div className="landing">
      <div className="landing__menu">
        {!isAuthorized && (
          <Modal
            color="danger"
            btn="Danger"
            title="Not Authorized!"
            toggle={() => setIsAuthorized(true)}
            isSuggetion
            message="Contact your administrator."
            // header
          />
        )}
        <Container>
          <Row>
            <Col>
              <div className="landing__menu-wrap">
                <div className="landing__menu-logo">
                  <img src={Logo} alt="Signpuns" />
                </div>
                <nav className="landing__menu-nav">
                  <div className="landing__menu-nav--button">
                    <Link
                      activeClass="active"
                      to="about"
                      spy
                      smooth
                      duration={500}
                    >
                      <button type="button">Intra-Source?</button>
                    </Link>
                  </div>
                  <div>
                    <a
                      className="landing__btn landing__btn--nav landing__btn--hire-us"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://signpuns.com/"
                    >
                      Emergency Contact
                    </a>
                  </div>
                </nav>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Header setIsAuthorized={setIsAuthorized} />
      <Element name="about" />
      <Footer />
    </div>
  );
};

Landing.propTypes = {
  dispatch: PropTypes.func.isRequired,
  theme: ThemeProps.isRequired,
};

export default connect((state) => ({ theme: state.theme }))(Landing);
