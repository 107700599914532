import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Container, Row } from "reactstrap";
import CheckCircleOutlineIcon from "mdi-react/CheckCircleOutlineIcon";
import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import moment from "moment";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/SignPunsApi";
import { PUN_TAG } from "@/shared/tagFile";
import { useUpdateWithParamsMutation } from "@/services/SignPunsApi";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import { getAllPun } from "../../SignSlice";
import PunsForm from "./PunsForm";
import "react-toastify/dist/ReactToastify.css";

const Puns = () => {
  const [punDetails, setPunDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [selectedStatusRecord, setSelectedStatusRecord] = useState();
  const [isApprovedOrRejectPun, setIsApprovedOrRejectPun] = useState(false);
  const [alreadyExistPun, setAlreadyExistPun] = useState();
  const [selectedApprovedOrRejectedPun, setSelectedApprovedOrRejectedPun] =
    useState("");
  const [selectedPunStatus, setSelectedPunStatus] = useState();
  const [deletePun, { isLoading: isDeleting }] = useDeleteMutation();
  const [updatePunStatus, { isLoading: isUpdatePunStatusLoading }] =
    useUpdateWithParamsMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [
    getPunRecords,
    {
      data: punList,
      isError: isPunError,
      isLoading: isPunLoading,
      isFetching: isPunFetching,
    },
  ] = useLazyGetRecordsQuery();
  const handleStatusChange = async (selectedPunDetail, selectedPunStatus) => {
    if (selectedPunStatus) {
      updatePunStatus({
        entity: `Pun/ApproveOrRejectPun?punId=${selectedPunDetail.Id}&statusId=${selectedPunStatus}`,
        tag: PUN_TAG,
      }).then((response) => {
        if (!response.error) {
          !isUpdatePunStatusLoading &&
            setIsApprovedOrRejectPun(!isApprovedOrRejectPun);
          selectedPunStatus === 2
            ? successToast("Pun Approved successfully.")
            : successToast("Pun rejected successfully.");
        }
      });
    }
  };

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "User Name",
      accessor: "UserName",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Pun Name",
      accessor: "Name",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Pun Line 1",
      accessor: "Line1",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Pun Line 2",
      accessor: "Line2",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Pun Line 3",
      accessor: "Line3",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Pun Line 4",
      accessor: "Line4",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Pun Line 5",
      accessor: "Line5",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Status",
      accessor: "Status.Name",
      filter: true,
      Cell: (data) => {
        return data.value ? (
          <div className="d-flex justify-content-around">
            {data.value === "Pending" ? (
              <div>
                <CheckCircleOutlineIcon
                  title="Approved"
                  className="cursor-pointer mx-2"
                  color="green"
                  onClick={(e) => {
                    setIsApprovedOrRejectPun(true);
                    setSelectedApprovedOrRejectedPun(data.cell.row.original);
                    setSelectedPunStatus(2);
                  }}
                />
                <CloseCircleOutlineIcon
                  className="cursor-pointer mx-2"
                  color="red"
                  onClick={(e) => {
                    setIsApprovedOrRejectPun(true);
                    setSelectedApprovedOrRejectedPun(data.cell.row.original);
                    setSelectedPunStatus(3);
                  }}
                />
              </div>
            ) : data.value === "Approved" ? (
              <span className="badge badge-success">{data.value}</span>
            ) : (
              <span className="badge badge-danger">{data.value}</span>
            )}
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      Header: "Donated",
      accessor: "IsDonated",
      type: "Boolean",
      Cell: ({ value }) => {
        return value ? "Yes" : "No";
      },
    },
    {
      Header: "Submitted",
      accessor: "DateSubmitted",
      type: "Boolean",
      Cell: ({ value }) => {
        return value ? value.split("T")[0].split("-").reverse().join("-") : "-"
      },
    },
    {
      Header: "Published",
      accessor: "PublishDate",
      type: "Boolean",
      Cell: ({ value }) => {
        return value ? value.split("T")[0].split("-").reverse().join("-") : "-"
      },
    },
  ];

  const handleEditData = (punData) => {
    setSelectedRecord(punData);
    setSelectedStatusRecord(punData.Status);
    setIsAddModalOpen(true);
  };
  const handleShowDetails = (punData) => {
    history.push({
      pathname: "/sign/pun-details",
      state: {
        PunId: punData.Id,
      },
    });
  };

  const handleDelete = () => {
    deletePun({
      entity: "pun",
      id: selectedRecord.Id,
      tag: PUN_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Pun deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getPunRecords({
      entity: "Pun/GetPunList",
      top: pageSize,
      expand: {
        Status: {},
      },
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: PUN_TAG,
    });
    setPunDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (!isPunLoading && !isPunError && punList && !isPunFetching) {
      dispatch(getAllPun(punList.value));
      let punData = [...punList.value];
      if (selectedRecord) {
        setSelectedRecord(
          (prev) => punList.value.find((value) => value.Id === prev.Id)?.Pun
        );
      }
      setPunDetails({
        ...punDetails,
        tableRowsData: punList.value ? punData : [],
        totalData:
          punList && punList["@odata.count"] ? punList["@odata.count"] : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
        onDisplayDetailClick: (data) => {
          handleShowDetails(data);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [isPunError, punList, isPunLoading, isPunFetching]);

  return (
    <Container>
      <Row>
        {punDetails && (
          <DataReactTable
            reactTableData={punDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="pun"
            isFetching={isPunFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} pun`}
          header
          btn="Success"
          message={
            <PunsForm
              editData={selectedRecord ? selectedRecord : {}}
              editStatusData={selectedStatusRecord ? selectedStatusRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
              setAlreadyExistPun={setAlreadyExistPun}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
      {isApprovedOrRejectPun && selectedPunStatus && (
        <Modal
          color={selectedPunStatus === 2 ? "success" : "danger"}
          title={`${selectedPunStatus === 2 ? "Approve" : "Reject"} Pun`}
          message={`Are you sure you want to ${selectedPunStatus === 2 ? "Approve" : "Reject"
            } Pun?`}
          handleOkClick={() =>
            handleStatusChange(selectedApprovedOrRejectedPun, selectedPunStatus)
          }
          toggle={() => setIsApprovedOrRejectPun(!isApprovedOrRejectPun)}
          isDeleting={isUpdatePunStatusLoading}
        />
      )}
      {alreadyExistPun && (
        <Modal
          color="warning"
          title="Warning"
          message={`The PublishDate you are working with will have an impact on PunOfTheDay`}
          handleOkClick={() => {
            setAlreadyExistPun()
            successToast(`Pun ${alreadyExistPun} successfully.`);
          }}
          toggle={() => setAlreadyExistPun()}
          isDeleting={isDeleting}
          removeCancelButton
        />
      )}
    </Container>
  );
};

export default Puns;
