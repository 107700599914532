import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { FormGroup, Label } from "reactstrap";

const AsyncPaginates = ({
  value = {},
  label,
  name,
  entity,
  fieldName = "Name",
  filter = "",
  query = "",
  setFieldValue,
  fieldErrors,
  fieldTouched,
  handleBlur,
  readonly = false,
  required = false,
  expandRelation = [],
}) => {
  const loadOptions = async (e, _, { page }) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const urlString = `${baseUrl}${entity}?$skip=${
      (page - 1) * 5
    }&$top=5&$count=true&$orderby=Id desc&$filter=IsDeleted ne true ${
      filter.length ? `and ${filter}` : ""
    }${query.length ? `&${query}` : ""}`;

    let updatedUrlString = "";
    if (value && e) updatedUrlString = urlString;
    if (e || !value)
      updatedUrlString =
        urlString + ` and (contains(Name,'${e.replaceAll("'", "''")}'))`;
    if (!e) updatedUrlString = urlString;
    let accessToken = localStorage.getItem("accessToken");
    const response = await fetch(updatedUrlString, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const responseJSON = await response.json();

    return {
      options: responseJSON.value,
      hasMore: page * 5 < responseJSON["@odata.count"],
      additional: {
        page: page + 1,
      },
    };
  };
  return (
    <FormGroup>
      <Label for={name}>
        {label}
        {required && <font color="red"> *</font>}
      </Label>
      <AsyncPaginate
        className={`lazySelect ${readonly && "disabled"}`}
        value={value}
        loadOptions={loadOptions}
        getOptionValue={(option) => option.Id}
        getOptionLabel={(option) => {
          if (expandRelation.length && option && option[expandRelation[0]]) {
            let optionString = `${option.Id}`;
            optionString +=
              "-" +
              expandRelation
                .map((value) => {
                  return `${option[value].Name}`;
                })
                .join("-");
            return optionString;
          } else if (entity === "Tile") {
            return `${option.Id} - ${option.UrlFileName}`;
          } else {
            return `${option.Id} - ${option[fieldName]}`;
          }
        }}
        placeholder={`Select ${label}`}
        isSearchable={true}
        onBlur={handleBlur}
        onChange={(e) => setFieldValue(name, e)}
        isDisabled={readonly}
        additional={{
          page: 1,
        }}
      />
      {fieldTouched && fieldErrors && <font color="red">{fieldErrors}</font>}
    </FormGroup>
  );
};

export default AsyncPaginates;
