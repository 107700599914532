import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col } from "reactstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/SignPunsApi";
import Modal from "@/shared/components/Modal";
import { successToast } from "@/shared/components/toast";
import CrosslinkPanel from "@/shared/components/entity_detail/CrosslinkPanel";
import { PUN_TAG } from "@/shared/tagFile";
import AddModal from "@/shared/components/AddModal";
import PunsMediaForm from "../PunsMedia/PunsMediaForm";

const Crosslinks = ({ displayData }) => {
  const { t } = useTranslation("common");
  const [deletePunMedia, { isLoading: isDeleting }] = useDeleteMutation();
  const [punMediaDetails, setPunMediaDetails] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState({
    screen: false,
  });
  const history = useHistory();

  const [
    getPunMediaRecords,
    {
      data: punMediaData,
      isError: isPunMediaError,
      isFetching: isPunMediaFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    getPunMediaRecords({
      entity: "PunMedia",
      top: 3,
      filter: `PunId eq ${displayData.PunId}`,
      expand: { Pun: {}, Media: {} },
      tag: PUN_TAG,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isPunMediaFetching && !isPunMediaError && punMediaData) {
      setPunMediaDetails(punMediaData.value);
    }
  }, [punMediaData, isPunMediaError, isPunMediaFetching]);

  const onAddClick = (title) => {
    let formName = `${title.charAt(0).toLowerCase()}${title
      .slice(1)
      .replace(/\s|\(|[0-9]|\)/g, "")}`;
    setIsAddModalOpen({ ...isAddModalOpen, [formName]: true });
  };

  const handleDelete = () => {
    deletePunMedia({
      entity: deleteRecord.entity,
      id: deleteRecord.Id,
      tag: deleteRecord.tag,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast(`${deleteRecord.entity} deleted successfully.`);
      }
    });
  };

  return (
    <>
      <Col xl={3}>
        <Card className="vh-90">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">
                {t("signpuns.org.entity.crosslinks.section_title")}
              </h5>
            </div>
            <div className="tabs tabs--bordered-bottom">
              <CrosslinkPanel
                title={`PunMedia (${
                  punMediaData && punMediaData["@odata.count"]
                    ? punMediaData["@odata.count"]
                    : "0"
                })`}
                onAddClick={onAddClick}
                description={
                  punMediaDetails && punMediaDetails.length > 0 ? (
                    <div>
                      {punMediaDetails.map((value, index) => {
                        return (
                          <div key={index}>
                            <div className="border-bottom-secondary d-flex">
                              <div
                                className="align-self-center mr-2 cursor-pointer"
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setDeleteRecord({
                                    ...value,
                                    entity: `PunMedia`,
                                    tag: PUN_TAG,
                                  });
                                }}
                              >
                                <MinusCircleIcon />
                              </div>
                              <div
                                className="border-bottom-secondary"
                                key={index}
                              >
                                <div>
                                  <b>Media Name: </b>
                                  {value.Media.Name}
                                </div>
                                <div className="">
                                  <a
                                    href={value.Media.CloudinaryAddress}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={
                                        value.Media &&
                                        value.Media.CloudinaryAddress &&
                                        value.Media.CloudinaryAddress
                                      }
                                      alt="pun-media"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                        );
                      })}
                      <div className="typography-message">
                        <Link
                          to="#"
                          className="disabledCursor"
                          onClick={(event) => {
                            history.push("/sign/puns-media");
                          }}
                        >
                          View All Records
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div>No Records Found</div>
                  )
                }
                colorStyle="success"
              ></CrosslinkPanel>
            </div>
          </CardBody>
        </Card>
      </Col>
      {isAddModalOpen.punMedia && (
        <AddModal
          toggle={() =>
            setIsAddModalOpen({ ...isAddModalOpen, punMedia: false })
          }
          color="success"
          title={`${displayData ? "Edit" : "Add"} Pun Media`}
          header
          btn="Success"
          xl
          message={
            <PunsMediaForm
              parentData={displayData}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </>
  );
};

export default Crosslinks;
