import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { CATEGORY_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { useAddMutation, useUpdateMutation } from "@/services/SignPunsApi";

const CategoryForm = ({ editData, setIsAddModalOpen }) => {
  const [addCategory, { isLoading: isCategoryAddLoading }] = useAddMutation();
  const [updateCategory, { isLoading: isCategoryUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData ? editData : null
  );

  const categoryValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter category name."),
  });

  const onSubmit = (values) => {
    const categoryDetail = {
      Name: values.Name,
      Description: values.Description,
      SortOrder: values.SortOrder ? values.SortOrder : 0,
    };
    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      categoryDetail.Id = editRecordDetail.Id;
      updateCategory({
        entity: "Category",
        data: categoryDetail,
        tag: CATEGORY_TAG,
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(false);
          successToast("Category updated successfully.");
        }
      });
    } else {
      addCategory({
        entity: "Category",
        data: categoryDetail,
        tag: CATEGORY_TAG,
      }).then((response) => {
        if (!response.error) {
          setEditRecordDetail(response.data);
          setIsAddModalOpen(false);
          successToast("Category added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name:
          editRecordDetail && editRecordDetail.Name
            ? editRecordDetail.Name
            : "",
        Description:
          editRecordDetail && editRecordDetail.Description
            ? editRecordDetail.Description
            : "",
        SortOrder:
          editRecordDetail && editRecordDetail.SortOrder
            ? editRecordDetail.SortOrder
            : "",
      }}
      onSubmit={onSubmit}
      validationSchema={categoryValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Category Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isCategoryAddLoading || isCategoryUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isCategoryAddLoading || isCategoryUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

CategoryForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func,
};

export default CategoryForm;
