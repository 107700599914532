import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/SignPunsApi";
import { SITE_ROLE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const SiteRoleForm = ({ editData, setIsAddModalOpen }) => {
  const [addSiteRole, { isLoading: isSiteRoleAddLoading }] = useAddMutation();
  const [updateSiteRole, { isLoading: isSiteRoleUpdateLoading }] =
    useUpdateMutation();

  const siteRoleValidationSchema = Yup.object().shape({
    Role: Yup.mixed().required("Please select role."),
    User: Yup.mixed().required("Please select user."),
  });

  const onSubmit = (values) => {
    const siteRoleDetail = {
      RoleTypeId: values.Role.Id,
      UserId: values.User.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      siteRoleDetail.Id = editData.Id;
      updateSiteRole({
        entity: "SiteRole",
        data: siteRoleDetail,
        tag: SITE_ROLE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isSiteRoleUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Site-role updated successfully.");
        }
      });
    } else {
      addSiteRole({
        entity: "SiteRole",
        data: siteRoleDetail,
        tag: SITE_ROLE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isSiteRoleAddLoading && setIsAddModalOpen(false);
          successToast("Site-role added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Role: editData && editData.RoleType ? editData.RoleType : "",
        User: editData && editData.User ? editData.User : "",
      }}
      validationSchema={siteRoleValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;
        return (
          <Form>
            <AsyncPaginates
              value={values.Role}
              label="Role"
              name="Role"
              entity="RoleType"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Role}
              fieldTouched={touched.Role}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.User}
              label="User"
              name="User"
              entity="User"
              setFieldValue={setFieldValue}
              fieldErrors={errors.User}
              fieldTouched={touched.User}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={isSiteRoleAddLoading || isSiteRoleUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isSiteRoleAddLoading || isSiteRoleUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

SiteRoleForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default SiteRoleForm;
