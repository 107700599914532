import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("1");

  const { t } = useTranslation("common");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            {t("signpuns.org.entity.activity.overview.title")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            {t("signpuns.org.entity.activity.notes.title")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggle("3")}
          >
            {t("signpuns.org.entity.activity.messages.title")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "4" })}
            onClick={() => toggle("4")}
          >
            {t("signpuns.org.entity.activity.orders.title")}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <p>
            Here is a summary of the counts of all activities regardless of
            type.
          </p>
        </TabPane>
        <TabPane tabId="2">
          <p>
            Here is a list of notes made on the Entity in chronological order
          </p>
        </TabPane>
        <TabPane tabId="3">
          <p>
            Here is a list of messages involving this Entity in chronological
            order
          </p>
        </TabPane>
        <TabPane tabId="4">
          <p>Here is a list of orders by the Entity</p>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Tabs;
