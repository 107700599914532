import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/SignPunsApi";
import { successToast } from "@/shared/components/toast";
import { PUN_MEDIA_TAG, PUN_TAG } from "@/shared/tagFile";
import AsyncPaginates from "@/shared/components/LazySelect";

const PunsMediaForm = ({
  editData,
  setIsAddModalOpen,
  isAddModalOpen,
  parentData,
}) => {
  const [addPunMedia, { isLoading: isPunMediaAddLoading }] = useAddMutation();
  const [updatePunMedia, { isLoading: isPunMediaUpdateLoading }] =
    useUpdateMutation();

  const PunMediaValidationSchema = Yup.object().shape({
    Pun: Yup.mixed().required("Please select pun."),
    File:
      editData && Object.entries(editData).length > 0
        ? Yup.mixed()
        : Yup.mixed().required("Please select file."),
  });

  const onSubmit = async (values) => {
    var formData = new FormData();
    if (editData && Object.entries(editData).length > 0) {
      if (values.File) {
        formData.append("Photo", values.File);
      }
      updatePunMedia({
        entity: `PunMedia`,
        query: `punId=${values.Pun.Id}`,
        Id: editData.Id,
        data: formData,
        tag: [PUN_MEDIA_TAG, PUN_TAG],
      }).then((response) => {
        if (!response.error) {
          !isPunMediaUpdateLoading && setIsAddModalOpen(false);
          successToast("Pun-media updated successfully.");
        }
      });
    } else {
      formData.append("Photo", values.File);
      addPunMedia({
        entity: `PunMedia?punId=${
          values.Pun.Id ? values.Pun.Id : parentData.PunId
        }`,
        data: formData,
        tag: [PUN_MEDIA_TAG, PUN_TAG],
      }).then((response) => {
        if (!response.error) {
          !isPunMediaAddLoading && setIsAddModalOpen(false);
          successToast("Pun-media added successfully.");
        }
      });
    }
  };
  return (
    <Formik
      initialValues={{
        Pun:
          editData && editData?.Pun
            ? editData?.Pun
            : parentData?.Pun
            ? parentData.Pun
            : "",
        File:
          editData && editData?.File
            ? editData?.File
            : parentData?.File
            ? parentData?.File
            : "",
      }}
      validationSchema={PunMediaValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;
        return (
          <Form>
            <AsyncPaginates
              value={values.Pun}
              label="Pun Line 1"
              name="Pun"
              entity="Pun"
              fieldName="Line1"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Pun}
              fieldTouched={touched.Pun}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Line1}
              required
            />
            {editData && editData.Media && !values.File && (
              <img
                src={editData.Media.CloudinaryAddress}
                alt={editData.Media.CloudinaryAddress}
              />
            )}
            <CustomInput
              label="File"
              name="File"
              type="file"
              handleChange={(e) => {
                setFieldValue("File", e.target.files[0]);
              }}
              fieldErrors={errors.File}
              fieldTouched={touched.File}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={isPunMediaAddLoading || isPunMediaUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isPunMediaAddLoading || isPunMediaUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

PunsMediaForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func.isRequired,
  isAddModalOpen: PropTypes.object,
  parentData: PropTypes.object,
};

export default PunsMediaForm;
