import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import LeftIcon from "mdi-react/KeyboardArrowLeftIcon";
import EditIcon from "mdi-react/PencilIcon";

const SharedMediashow = ({
  setIsEyeClicked,
  title,
  displayData,
  setIsAddModalOpen,
  image,
}) => {
  return (
    <Container>
      {displayData ? (
        <Row>
          <Col xl={9}>
            <Card className="vh-90">
              <CardBody>
                <div className="card__title">
                  <div
                    className="bold-text cursor-pointer text-primary"
                    onClick={() => {
                      setIsEyeClicked(false);
                    }}
                  >
                    <LeftIcon />
                    {title} Media Details
                  </div>
                </div>
                <div className="tabs tabs--bordered-bottom">
                  <div className="d-flex mt-3">
                    <div className=" border border-dark">
                      <img
                        className="w-100 h-100"
                        src={image && image}
                        alt="avatar"
                      />
                    </div>
                    <div className="profile__data">
                      <p className="profile__name">
                        {title} Media Name:-{" "}
                        {displayData && displayData.Media?.Name}
                      </p>
                      <p className="profile__work">
                        {displayData[`${title}Type`] &&
                          displayData[`${title}Type`].Name}
                      </p>
                      <div className="mt-5">
                        <p className="profile__name">
                          {title} Name:-{" "}
                          {displayData && displayData[title]?.Line1
                            ? displayData[title].Name
                            : null}
                        </p>
                      </div>
                    </div>
                    {setIsAddModalOpen && (
                      <div className="cursor-pointer">
                        <EditIcon onClick={() => setIsAddModalOpen(true)} />
                      </div>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <Spinner size="sm" variant="light" />
      )}
    </Container>
  );
};

SharedMediashow.propTypes = {
  setIsEyeClicked: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  displayData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
  image: PropTypes.string,
};

export default SharedMediashow;
