import { createSlice } from "@reduxjs/toolkit";

const SignSlice = createSlice({
  name: "sign",
  initialState: {
    pun: [],
    punMedia: [],
    punCategory: [],
    category: [],
    categoryMedia: [],
    punUser: [],
  },
  reducers: {
    getAllPun: (state, action) => {
      state.pun = action.payload;
    },
    getAllPunMedia: (state, action) => {
      state.punMedia = action.payload;
    },
    getAllPunCategory: (state, action) => {
      state.punCategory = action.payload;
    },
    getAllCategory: (state, action) => {
      state.category = action.payload;
    },
    getAllCategoryMedia: (state, action) => {
      state.categoryMedia = action.payload;
    },
    getAllPunUser: (state, action) => {
      state.punUser = action.payload;
    },
  },
});

export default SignSlice.reducer;
export const {
  getAllPun,
  getAllPunMedia,
  getAllPunCategory,
  getAllCategory,
  getAllCategoryMedia,
  getAllPunUser,
} = SignSlice.actions;
