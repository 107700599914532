import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { errorToast } from "../shared/components/toast";
import { TagType } from "../shared/tagFile";

const errorModal = (error, arg) => {
  let errorMessage = "";
  switch (error.status) {
    case 404:
      errorMessage = "Error 404 not found";
      break;
    case 500:
      errorMessage = "Error 500 internal server error";
      break;
    case 401:
      errorMessage = "Error 401 unauthorized";
      break;
    case 400:
      errorMessage = error.data.value ? error.data.value : "Error 400 bad request";
      break;
    case 417:
      errorMessage = error.data.value;
      break;
    default:
      errorMessage = `Something went wrong calling ${arg.entity}`;
      break;
  }
  errorToast(errorMessage);
};

const expandChildQuery = (expand) => {
  return (
    "$expand=" +
    Object.entries(expand)
      .map(
        (o) => `${o[0]}
          ${
            o[1].fields
              ? `($filter=IsDeleted ne true;${
                  o[1].top ? `$top=${o[1].top};` : ""
                }$select=${o[1].fields};${
                  o[1].expand
                    ? expandChildQuery(o[1].expand)
                    : o[1].filter
                    ? `($filter=${o[1].filter})`
                    : ""
                })`
              : `${
                  o[1].expand
                    ? `($filter=IsDeleted ne true;${
                        o[1].top ? `$top=${o[1].top};` : ""
                      }${expandChildQuery(o[1].expand)})`
                    : o[1].filter
                    ? `($filter=${o[1].filter})`
                    : ""
                }`
          }`
      )
      .join(",")
      .trim()
  );
};

export const SignPunsApi = createApi({
  reducerPath: "SignPunsApi",
  tagTypes: TagType,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    getRecords: builder.query({
      query: ({
        entity,
        subEntity = "",
        filter = "",
        sort = [],
        skip = 0,
        top = 10,
        expand = {},
        query = "",
        globalFilter = {},
      }) => {
        let finalUrl = `${entity}`;
        let filterQuery = "$filter=IsDeleted ne true";
        if (subEntity !== "") {
          finalUrl += `/${subEntity}`;
        }
        if (Object.entries(globalFilter).length > 0) {
          if (
            globalFilter.globalSearchKeyword &&
            globalFilter.globalSearchKeyword !== ""
          ) {
            let filterData = [];
            globalFilter.columns.forEach((value) => {
              if (
                value.Header !== "Action" &&
                value.Header !== "Media" &&
                typeof value.accessor !== "function" &&
                value.type !== "Boolean"
              ) {
                if (value.type !== "Number") {
                  filterData.push(
                    `contains(${value.accessor.replace(".", "/")},'${globalFilter.globalSearchKeyword
                    }')`
                  );
                } else if (!isNaN(globalFilter.globalSearchKeyword)) {
                  filterData.push(
                    `${value.accessor} eq ${globalFilter.globalSearchKeyword}`
                  );
                }
              }
            });
            filterQuery += ` and (${filterData.join(" or ")})`;
          }
        }
        if (filter !== "") {
          filterQuery += ` and (${filter})`;
        }
        finalUrl += `?$skip=${skip}&$top=${top}&$count=true`;
        if (query !== "") {
          finalUrl = `${finalUrl}&${query}`;
        }
        if (sort.length > 0) {
          let sortQuery = "&$orderby=";
          sortQuery += sort.map((value) => {
            return `${value.columnName} ${value.direction}`;
          });
          finalUrl += sortQuery;
        } else {
          finalUrl += "&$orderby=Id desc";
        }
        if (Object.entries(expand).length > 0) {
          let expandQueryStr = "";
          expandQueryStr = "&" + expandChildQuery(expand);
          finalUrl += expandQueryStr;
          Object.entries(expand).forEach((o) => {
            if (!o[1].expand && !o[1].filter) {
              filterQuery += ` and ${o[0]}/IsDeleted ne true`;
            }
          });
        }
        finalUrl += `&${filterQuery}`;
        let accessToken = localStorage.getItem("accessToken");
        return {
          url: `${finalUrl}`,
          method: "GET",
          headers: {
            "content-type": `application/json;odata=verbose`,
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: (result, error, arg) => {
        if (error) {
          errorModal(error, arg);
        }
        return arg.tag ? [arg.tag] : [];
      },
    }),
    getRecordsById: builder.query({
      query: ({ entity, id, expand }) => {
        let finalUrl = `${entity}/${id}`;
        if (expand && Object.entries(expand).length > 0) {
          let expandQueryStr = "";
          expandQueryStr = "?" + expandChildQuery(expand);
          finalUrl += expandQueryStr;
        }
        let accessToken = localStorage.getItem("accessToken");
        return {
          url: `${finalUrl}`,
          method: "GET",
          headers: {
            "content-type": `application/json;odata=verbose`,
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
    }),
    add: builder.mutation({
      query: ({ entity, data, params }) => {
        let accessToken = localStorage.getItem("accessToken");
        return {
          url: entity,
          method: "POST",
          body: data,
          params: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: (result, error, arg) => {
        if (error) {
          errorModal(error, arg);
        }
        return arg.tag
          ? typeof arg.tag === "object"
            ? [...arg.tag]
            : [arg.tag]
          : [];
      },
    }),
    update: builder.mutation({
      query: ({ entity, data, query = "", Id = 0 }) => {
        let accessToken = localStorage.getItem("accessToken");
        return {
          url: `${entity}/${Id ? Id : data.Id}${query ? `?${query}` : ""}`,
          method: "PUT",
          body: data,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: (result, error, arg) => {
        if (error) {
          errorModal(error, arg);
        }
        return arg.tag
          ? typeof arg.tag === "object"
            ? [...arg.tag]
            : [arg.tag]
          : [];
      },
    }),
    updateWithParams: builder.mutation({
      query: ({ entity, query = "" }) => {
        let accessToken = localStorage.getItem("accessToken");
        return {
          url: `${entity}${query ? `?${query}` : ""}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: (result, error, arg) => {
        if (error) {
          errorModal(error, arg);
        }
        return arg.tag
          ? typeof arg.tag === "object"
            ? [...arg.tag]
            : [arg.tag]
          : [];
      },
    }),
    delete: builder.mutation({
      query: ({ entity, id }) => {
        let accessToken = localStorage.getItem("accessToken");
        return {
          url: `${entity}/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: (result, error, arg) => {
        if (error) {
          errorModal(error, arg);
        }
        return arg.tag ? [arg.tag] : [];
      },
    }),
  }),
});

export const {
  useGetRecordsQuery,
  useLazyGetRecordsQuery,
  useGetRecordsByIdQuery,
  useLazyGetRecordsByIdQuery,
  useAddMutation,
  useUpdateMutation,
  useUpdateWithParamsMutation,
  useDeleteMutation,
} = SignPunsApi;
