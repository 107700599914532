import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/SignPunsApi";
import { successToast } from "@/shared/components/toast";
import { CATEGORY_MEDIA_TAG, CATEGORY_TAG } from "@/shared/tagFile";
import AsyncPaginates from "@/shared/components/LazySelect";

const CategoryMediaForm = ({ editData, setIsAddModalOpen }) => {
  const [addCategoryMedia, { isLoading: isCategoryMediaAddLoading }] = useAddMutation();
  const [updateCategoryMedia, { isLoading: isCategoryMediaUpdateLoading }] =
    useUpdateMutation();

  const CategoryMediaValidationSchema = Yup.object().shape({
    Category: Yup.mixed().required("Please select category."),
    File:
      editData && Object.entries(editData).length > 0
        ? Yup.mixed()
        : Yup.mixed().required("Please select file."),
  });

  const onSubmit = async (values) => {
    var formData = new FormData();
    if (editData && Object.entries(editData).length > 0) {
      if (values.File) {
        formData.append("Photo", values.File);
      }
      updateCategoryMedia({
        entity: `CategoryMedia`,
        query: `id=${editData.Id}&categoryId=${values.Category.Id}`,
        Id: editData.Id,
        data: formData,
        tag: [CATEGORY_MEDIA_TAG, CATEGORY_TAG],
      }).then((response) => {
        if (!response.error) {
          !isCategoryMediaUpdateLoading && setIsAddModalOpen(false);
          successToast("Category-media updated successfully.");
        }
      });
    } else {
      formData.append("Photo", values.File);
      addCategoryMedia({
        entity: `CategoryMedia?categoryId=${values.Category.Id}`,
        data: formData,
        tag: [CATEGORY_MEDIA_TAG, CATEGORY_TAG],
      }).then((response) => {
        if (!response.error) {
          !isCategoryMediaAddLoading && setIsAddModalOpen(false);
          successToast("Category-media added successfully.");
        }
      });
    }
  };
  return (
    <Formik
      initialValues={{
        Category: editData && editData.Category ? editData.Category : "",
        File: "",
      }}
      validationSchema={CategoryMediaValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;
        return (
          <Form>
            <AsyncPaginates
              value={values.Category}
              label="Category"
              name="Category"
              entity="Category"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Category}
              fieldTouched={touched.Category}
              handleBlur={handleBlur}
              required
            />
            {editData && editData.Media && !values.File && (
              <img
                src={editData.Media.CloudinaryAddress}
                alt={editData.Media.CloudinaryAddress}
              />
            )}
            <CustomInput
              label="File"
              name="File"
              type="file"
              handleChange={(e) => {
                setFieldValue("File", e.target.files[0]);
              }}
              fieldErrors={errors.File}
              fieldTouched={touched.File}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={isCategoryMediaAddLoading || isCategoryMediaUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isCategoryMediaAddLoading || isCategoryMediaUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

CategoryMediaForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default CategoryMediaForm;
