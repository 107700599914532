import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/SignPunsApi";
import { PRODUCT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ProductForm = ({ editData, setIsAddModalOpen }) => {
  const [addProduct, { isLoading: isProductAddLoading }] = useAddMutation();
  const [updateProduct, { isLoading: isProductUpdateLoading }] =
    useUpdateMutation();

  const productValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
  });

  const onSubmit = (values) => {
    let productDetail = {
      Name: values.Name,
      Description: values.Description,
      SortOrder: values.SortOrder ? values.SortOrder : "999",
    };
    if (editData && Object.entries(editData).length > 0) {
      productDetail.Id = editData.Id;
      updateProduct({
        entity: "Product",
        data: productDetail,
        tag: PRODUCT_TAG,
      }).then((response) => {
        if (!response.error) {
          !isProductUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Product updated successfully.");
        }
      });
    } else {
      addProduct({
        entity: "Product",
        data: productDetail,
        tag: PRODUCT_TAG,
      }).then((response) => {
        if (!response.error) {
          !isProductAddLoading && setIsAddModalOpen(false);
          successToast("Product added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        SortOrder: editData && editData.SortOrder ? editData.SortOrder : "",
      }}
      validationSchema={productValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isProductAddLoading || isProductUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isProductAddLoading || isProductUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ProductForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ProductForm;
