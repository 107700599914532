import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/SignPunsApi";
import { PUN_MEDIA_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import SharedMediashow from "@/shared/components/SharedMediaShow";
import { formateColumnFilter } from "@/utils/columnFilter";
import { getAllPunMedia } from "../../SignSlice";
import PunsMediaForm from "./PunsMediaForm";
import "react-toastify/dist/ReactToastify.css";

const PunsMedia = () => {
  const [isEyeClicked, setIsEyeClicked] = useState(false);
  const [punMediaDetails, setPunMediaDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deletePunMedia, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getPunMediaRecords,
    {
      data: punMediaList,
      isError: isPunMediaError,
      isLoading: isPunMediaLoading,
      isFetching: isPunMediaFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Pun Line 1",
      accessor: "Pun.Line1",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Media Name",
      accessor: "Media.Name",
      filter: true,
      Cell: (val) => {
        return (
          <a
            href={val?.row?.original?.Media?.CloudinaryAddress}
            target="_blank"
            rel="noreferrer"
          >
            {val.value}
          </a>
        );
      },
    },
    {
      Header: "Media Description",
      accessor: "Media.Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Media Full Text",
      accessor: "Media.FullText",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (PunMediaData) => {
    setSelectedRecord(PunMediaData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deletePunMedia({
      entity: "PunMedia",
      id: selectedRecord.Id,
      tag: PUN_MEDIA_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Pun-media deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getPunMediaRecords({
      entity: "PunMedia",
      top: pageSize,
      expand: { Pun: {}, Media: {} },
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: PUN_MEDIA_TAG,
    });
    setPunMediaDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isPunMediaLoading &&
      !isPunMediaError &&
      punMediaList &&
      !isPunMediaFetching
    ) {
      dispatch(getAllPunMedia(punMediaList.value));
      let PunMediaData = [...punMediaList.value];
      if (selectedRecord) {
        setSelectedRecord((prev) =>
          punMediaList.value.find((value) => value.Id === prev.Id)
        );
      }
      setPunMediaDetails({
        ...punMediaDetails,
        tableRowsData: punMediaList.value ? PunMediaData : [],
        totalData:
          punMediaList && punMediaList["@odata.count"]
            ? punMediaList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
        onDisplayDetailClick: (data) => {
          setIsEyeClicked(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [isPunMediaError, punMediaList, isPunMediaLoading, isPunMediaFetching]);
  return (
    <Container>
      <Row>
        {isEyeClicked && (
          <SharedMediashow
            setIsEyeClicked={() => {
              setIsEyeClicked(false);
              setSelectedRecord();
            }}
            title="Pun"
            displayData={selectedRecord}
            setIsAddModalOpen={setIsAddModalOpen}
            image={selectedRecord.Media?.CloudinaryAddress}
          />
        )}
        {punMediaDetails && !isEyeClicked && (
          <DataReactTable
            reactTableData={punMediaDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Pun Media"
            isFetching={isPunMediaFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Pun Media`}
          header
          btn="Success"
          message={
            <PunsMediaForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete ${selectedRecord.Pun.Line1}?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default PunsMedia;
