import React from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";

const SidebarContent = ({ onClick, sidebarCollapse }) => {
  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink
          icon="home"
          title="Dashboard"
          route="/dashboard"
          onClick={onClick}
        />
      </ul>
      <ul className="sidebar__block">
        <SidebarCategory
          title="Org"
          icon="list"
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarLink title="Role" route="/org/role" onClick={onClick} />
          <SidebarLink
            title="Role Type"
            route="/org/role-type"
            onClick={onClick}
          />
        </SidebarCategory>
      </ul>
      <ul className="sidebar__block">
        <SidebarCategory
          title="Catalog"
          icon="list"
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarLink
            title="Product"
            route="/catalog/product"
            onClick={onClick}
          />
          <SidebarLink
            title="Product Category"
            route="/catalog/product-category"
            onClick={onClick}
          />
        </SidebarCategory>
      </ul>
      <ul className="sidebar__block">
        <SidebarCategory
          title="Sign"
          icon="list"
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarCategory title="Pun" sidebarCollapse={sidebarCollapse}>
            <SidebarLink title="Puns" route="/sign/puns" onClick={onClick} />
            <SidebarLink
              title="Puns Media"
              route="/sign/puns-media"
              onClick={onClick}
            />
            <SidebarLink
              title="Puns Category"
              route="/sign/puns-category"
              onClick={onClick}
            />
            <SidebarLink
              title="Puns User"
              route="/sign/pun-user"
              onClick={onClick}
            />
          </SidebarCategory>
          <SidebarCategory title="Category" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Category"
              route="/sign/category"
              onClick={onClick}
            />
            <SidebarLink
              title="Category Media"
              route="/sign/category-media"
              onClick={onClick}
            />
          </SidebarCategory>
        </SidebarCategory>
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
  onClick: () => {},
};

export default SidebarContent;
