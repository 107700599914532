import React from "react";
import { Route } from "react-router-dom";
import Puns from "@/components/Sign/Pun/Puns/Puns";
import PunsMedia from "@/components/Sign/Pun/PunsMedia/PunsMedia";
import PunsCategory from "@/components/Sign/Pun/PunsCategoy/PunsCategory";
import Category from "@/components/Sign/Category/Category/Category";
import CategoryMedia from "@/components/Sign/Category/CategoryMedia/CategoryMedia";
import PunDetail from "@/components/Sign/Pun/PunDetail/PunDetail";
import PunUser from "@/components/Sign/Pun/PunUser/PunUser";

const SignRoutes = () => (
  <div>
    <Route path="/sign/puns" component={Puns} />
    <Route path="/sign/puns-media" component={PunsMedia} />
    <Route path="/sign/puns-category" component={PunsCategory} />
    <Route path="/sign/pun-user" component={PunUser} />
    <Route path="/sign/pun-details" component={PunDetail} />
    <Route path="/sign/category" component={Category} />
    <Route path="/sign/category-media" component={CategoryMedia} />
  </div>
);

export default SignRoutes;
