import React from "react";
import { Route } from "react-router-dom";
import SiteRole from "@/components/Org/Role/SiteRole";
import SiteRoleType from "@/components/Org/RoleType/SiteRoleType";

const OrgRoutes = () => (
  <div>
    <Route path="/org/role" component={SiteRole} />
    <Route path="/org/role-type" component={SiteRoleType} />
  </div>
);

export default OrgRoutes;
