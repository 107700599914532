import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/SignPunsApi";
import { SITE_ROLE_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const RoleTypeForm = ({ editData, setIsAddModalOpen }) => {
  const [addRoleType, { isLoading: isRoleTypeAddLoading }] = useAddMutation();
  const [updateRoleType, { isLoading: isRoleTypeUpdateLoading }] =
    useUpdateMutation();

  const roleTypeValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    SortOrder: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    const roleTypeDetail = {
      Name: values.Name,
      Description: values.Description,
    };

    if (values.RoleTypeParent.Id)
      roleTypeDetail.RoleTypeParentId = values.RoleTypeParent.Id;
    if (values.SortOrder) roleTypeDetail.SortOrder = parseInt(values.SortOrder);

    if (editData && Object.entries(editData).length > 0) {
      roleTypeDetail.Id = editData.Id;
      updateRoleType({
        entity: "RoleType",
        data: roleTypeDetail,
        tag: SITE_ROLE_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isRoleTypeUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Role-type updated successfully.");
        }
      });
    } else {
      addRoleType({
        entity: "RoleType",
        data: roleTypeDetail,
        tag: SITE_ROLE_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isRoleTypeAddLoading && setIsAddModalOpen(false);
          successToast("Role-type added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        RoleTypeParent: editData.RoleTypeParent ? editData.RoleTypeParent : "",
        AuthRoleId: editData && editData.AuthRoleId ? editData.AuthRoleId : "",
        SortOrder:
          editData?.SortOrder === 0
            ? 0
            : editData?.SortOrder
            ? editData?.SortOrder
            : 999,
      }}
      validationSchema={roleTypeValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.RoleTypeParent}
              label="RoleTypeParent"
              name="RoleTypeParent"
              entity="RoleType"
              setFieldValue={setFieldValue}
              fieldErrors={errors.RoleTypeParent}
              fieldTouched={touched.RoleTypeParent}
              handleBlur={handleBlur}
            />
            <CustomInput
              label="AuthRoleId"
              name="AuthRoleId"
              fieldErrors={errors.AuthRoleId}
              fieldTouched={touched.AuthRoleIdName}
              fieldValue={values.AuthRoleId}
              handleBlur={handleBlur}
              handleChange={handleChange}
              readonly
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isRoleTypeAddLoading || isRoleTypeUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isRoleTypeAddLoading || isRoleTypeUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

RoleTypeForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default RoleTypeForm;
