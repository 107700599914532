import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useAddMutation, useUpdateMutation } from "@/services/SignPunsApi";
import { PUN_CATEGORY_TAG, PUN_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import AsyncPaginates from "@/shared/components/LazySelect";

const PunsCategoryForm = ({ editData, setIsAddModalOpen, parentData }) => {
  const [addPunCategory, { isLoading: isPunCategoryAddLoading }] =
    useAddMutation();
  const [updatePunCategory, { isLoading: isPunCategoryUpdateLoading }] =
    useUpdateMutation();

  const punCategoryValidationSchema = Yup.object().shape({
    Pun: Yup.mixed().required("Please select pun."),
    Category: Yup.mixed().required("Please select Category."),
  });

  const onSubmit = (values) => {
    const punCategoryDetails = {
      punId: values.Pun.Id,
      categoryId: values.Category.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      punCategoryDetails.Id = editData.Id;
      updatePunCategory({
        entity: "PunCategory",
        data: punCategoryDetails,
        tag: [PUN_CATEGORY_TAG, PUN_TAG],
      }).then((response) => {
        if (!response.error) {
          !isPunCategoryUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Pun-Category updated successfully.");
        }
      });
    } else {
      addPunCategory({
        entity: "PunCategory",
        data: punCategoryDetails,
        tag: [PUN_CATEGORY_TAG, PUN_TAG],
      }).then((response) => {
        if (!response.error) {
          !isPunCategoryAddLoading && setIsAddModalOpen(false);
          successToast("Pun-Category added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Pun:
          editData && editData.Pun
            ? editData.Pun
            : parentData && parentData.Line1
            ? parentData
            : "",
        Category:
          editData && editData.Category
            ? editData.Category
            : parentData && parentData.Category
            ? parentData
            : "",
      }}
      validationSchema={punCategoryValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <AsyncPaginates
              value={values.Pun}
              label="Pun Line 1"
              name="Pun"
              entity="Pun"
              fieldName="Line1"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Pun}
              fieldTouched={touched.Pun}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Line1}
              required
            />
            <AsyncPaginates
              value={values.Category}
              label="Category"
              name="Category"
              entity="Category"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Category}
              fieldTouched={touched.Category}
              handleBlur={handleBlur}
              required
            />

            <Button
              disabled={isPunCategoryAddLoading || isPunCategoryUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isPunCategoryAddLoading || isPunCategoryUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

PunsCategoryForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
  parentData: PropTypes.object,
};

export default PunsCategoryForm;
