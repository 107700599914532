import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useAddMutation, useUpdateMutation } from "@/services/SignPunsApi";
import { PUN_USER_TAG, PUN_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import AsyncPaginates from "@/shared/components/LazySelect";

const PunUserForm = ({ editData, setIsAddModalOpen, parentData }) => {
  const [addPunUser, { isLoading: isPunUserAddLoading }] =
    useAddMutation();
  const [updatePunUser, { isLoading: isPunUserUpdateLoading }] =
    useUpdateMutation();

  const punUserValidationSchema = Yup.object().shape({
    Pun: Yup.mixed().required("Please select pun."),
    User: Yup.mixed().required("Please select User."),
  });

  const onSubmit = (values) => {
    const punUserDetails = {
      punId: values.Pun.Id,
      userId: values.User.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      punUserDetails.Id = editData.Id;
      updatePunUser({
        entity: "PunUser",
        data: punUserDetails,
        tag: [PUN_USER_TAG, PUN_TAG],
      }).then((response) => {
        if (!response.error) {
          !isPunUserUpdateLoading &&          
            setIsAddModalOpen(false) &&
            successToast("Pun-User updated successfully.");
        }
      });
    } else {
      addPunUser({
        entity: "PunUser",
        data: punUserDetails,
        tag: [PUN_USER_TAG, PUN_TAG],
      }).then((response) => {
        if (!response.error) {
          !isPunUserAddLoading && setIsAddModalOpen(false);
          successToast("Pun-User added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Pun:
          editData && editData.Pun
            ? editData.Pun
            : parentData && parentData.Line1
            ? parentData
            : "",
        User:
          editData && editData.User
            ? editData.User
            : parentData && parentData.User
            ? parentData
            : "",
      }}
      validationSchema={punUserValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <AsyncPaginates
              value={values.Pun}
              label="Pun Line 1"
              name="Pun"
              entity="Pun"
              fieldName="Line1"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Pun}
              fieldTouched={touched.Pun}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Line1}
              required
            />
            <AsyncPaginates
              value={values.User}
              label="User"
              name="User"
              entity="User"
              setFieldValue={setFieldValue}
              fieldErrors={errors.User}
              fieldTouched={touched.User}
              handleBlur={handleBlur}
              required
            />

            <Button
              disabled={isPunUserAddLoading || isPunUserUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isPunUserAddLoading || isPunUserUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

PunUserForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
  parentData: PropTypes.object,
};

export default PunUserForm;
