import React from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import {
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap";
import TopbarNavLink from "./TopbarNavLink";
import TopbarNavCategory from "./TopbarNavCategory";

const TopbarNavAdmin = () => (
  <UncontrolledDropdown className="topbar__nav-dropdown">
    <DropdownToggle className="topbar__nav-dropdown-toggle">
      Data Admin
      <DownIcon />
    </DropdownToggle>

    <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
      <DropdownItem>
        <TopbarNavLink title="Dashboard" icon="home" route="/dashboard" />
      </DropdownItem>

      <DropdownItem>
        <TopbarNavCategory title="Org" icon="list">
          <TopbarNavLink title="Role" route="/org/role" />
          <TopbarNavLink title="Role Type" route="/org/role-type" />
        </TopbarNavCategory>
      </DropdownItem>

      <DropdownItem>
        <TopbarNavCategory title="Catalog" icon="list">
          <TopbarNavLink title="Product" route="/catalog/product" />
          <TopbarNavLink
            title="Product Category"
            route="/catalog/product-category"
          />
        </TopbarNavCategory>
      </DropdownItem>

      <DropdownItem>
        <TopbarNavCategory title="Sign" icon="list">
          <TopbarNavCategory title="Pun" icon="list">
            <TopbarNavLink title="Puns" route="/sign/puns" />
            <TopbarNavLink title="Puns Media" route="/sign/puns-media" />
            <TopbarNavLink title="Puns Category" route="/sign/puns-category" />
            <TopbarNavLink title="Puns User" route="/sign/puns-user" />
          </TopbarNavCategory>

          <TopbarNavCategory title="Category" icon="list">
            <TopbarNavLink title="Category" route="/sign/category" />
            <TopbarNavLink
              title="Category Media"
              route="/sign/category-media"
            />
          </TopbarNavCategory>
        </TopbarNavCategory>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

export default TopbarNavAdmin;
