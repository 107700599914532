import { createSlice } from "@reduxjs/toolkit";

const CatalogSlice = createSlice({
  name: "catalog",
  initialState: {
    productCategory: [],
    product: [],
  },
  reducers: {
    getAllProductCategory: (state, action) => {
      state.productCategory = action.payload;
    },
    getAllProducts: (state, action) => {
      state.product = action.payload;
    },
  },
});

export default CatalogSlice.reducer;
export const { getAllProductCategory, getAllProducts } =
  CatalogSlice.actions;
