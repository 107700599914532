import { createSlice } from "@reduxjs/toolkit";

const OrgSlice = createSlice({
  name: "org",
  initialState: {
    role: [],
    roleType: [],
  },
  reducers: {
    getAllRoles: (state, action) => {
      state.role = action.payload;
    },
    getAllRoleTypes: (state, action) => {
      state.roleType = action.payload;
    },
  },
});

export default OrgSlice.reducer;
export const { getAllRoles, getAllRoleTypes } = OrgSlice.actions;
