import React from "react";
import { Route } from "react-router-dom";
import Product from "@/components/Catalog/Product/Product/Product";
import ProductCategory from "@/components/Catalog/Product/ProductCategory/ProductCategory";

const CatalogRoutes = () => (
  <div>
    <Route path="/catalog/product" component={Product} />
    <Route path="/catalog/product-category" component={ProductCategory} />
  </div>
);

export default CatalogRoutes;
