import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { USER } from "@/shared/userRoles";
import Loading from "@/shared/components/Loading";
import AppDashboard from "@/components/App/index";
import Layout from "../../../Layout/index";
import Account from "./Account";
import CatalogRoutes from "../CatalogRoutes";
import SignRoutes from "../SignRoutes";
import OrgRoutes from "../OrgRoutes";

const WrappedRoutes = () => {
  const role = useSelector((state) => state.user.role);
  if (role === USER) {
    return <Redirect to="/" />;
  }
  return (
    <div>
      <Layout />
      {role ? (
        <div className="container__wrap">
          <Route path="/dashboard" component={AppDashboard} />
          <Route path="/account" component={Account} />
          <Route path="/org" component={OrgRoutes} />
          <Route path="/sign" component={SignRoutes} />
          <Route path="/catalog" component={CatalogRoutes} />
        </div>
      ) : (
        <Loading loading={true} />
      )}
    </div>
  );
};

export default WrappedRoutes;
