import React, { useState } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { UserProps } from "@/shared/prop-types/ReducerProps";
import TopbarMenuLink from "./TopbarMenuLink";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = ({ user }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { logout } = useAuth0();

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  const logoutUser = () => {
    localStorage.removeItem("accessToken");
    logout({ returnTo: process.env.REACT_APP_LOGIN_REDIRECT });
  };

  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggleProfile}>
        <img
          className="topbar__avatar-img"
          src={(user && (user.picture || user.avatar)) || Ava}
          alt="avatar"
        />
        <p className="topbar__avatar-name">{user && user.name}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="profile button"
          onClick={toggleProfile}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title="My Profile"
            icon="user"
            path="/account/profile"
            onClick={toggleProfile}
          />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title="Log Out"
            icon="exit"
            onClick={logoutUser}
            path="/"
          />
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  user: UserProps.isRequired,
};

export default connect((state) => ({
  user: state.user,
}))(TopbarProfile);
