import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Label, Spinner } from "reactstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { PUN_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/SignPunsApi";
import moment from "moment";

const PunsForm = ({ editData, setIsAddModalOpen, editStatusData, setAlreadyExistPun }) => {
  const [addPun, { isLoading: isPunAddLoading }] = useAddMutation();
  const [updatePun, { isLoading: isPunUpdateLoading }] = useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData && editStatusData ? { editData, editStatusData } : null
  );
  const isDontedOptions = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];

  const punValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter pun name."),
    IsDonated: Yup.object().required("Please select donted."),
    ...(!Object.entries(editRecordDetail).length && {
      Category: Yup.object().required("Please select category."),
    }),
    DateSubmitted: Yup.date().required("Please select submitted date."),
    Line1: Yup.string().test(
      "oneOfRequired",
      "Please fill at least line.",
      (_, punLines) =>
        punLines.parent.Line1 ||
        punLines.parent.Line2 ||
        punLines.parent.Line3 ||
        punLines.parent.Line4 ||
        punLines.parent.Line5
    ),
    Line2: Yup.string().test(
      "oneOfRequired",
      "Please fill at least line.",
      (_, punLines) =>
        punLines.parent.Line1 ||
        punLines.parent.Line2 ||
        punLines.parent.Line3 ||
        punLines.parent.Line4 ||
        punLines.parent.Line5
    ),
    Line3: Yup.string().test(
      "oneOfRequired",
      "Please fill at least line.",
      (_, punLines) =>
        punLines.parent.Line1 ||
        punLines.parent.Line2 ||
        punLines.parent.Line3 ||
        punLines.parent.Line4 ||
        punLines.parent.Line5
    ),
    Line4: Yup.string().test(
      "oneOfRequired",
      "Please fill at least line.",
      (_, punLines) =>
        punLines.parent.Line1 ||
        punLines.parent.Line2 ||
        punLines.parent.Line3 ||
        punLines.parent.Line4 ||
        punLines.parent.Line5
    ),
    Line5: Yup.string().test(
      "oneOfRequired",
      "Please fill at least line.",
      (_, punLines) =>
        punLines.parent.Line1 ||
        punLines.parent.Line2 ||
        punLines.parent.Line3 ||
        punLines.parent.Line4 ||
        punLines.parent.Line5
    ),
  });

  const onSubmit = (values) => {
    var formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("Description", values.Description);
    values?.PublishDate ? formData.append("PublishDate", values.PublishDate) : formData.append("PublishDate", "");
    formData.append("DateSubmitted", values.DateSubmitted)
    formData.append("Line1", values.Line1);
    formData.append("Line2", values.Line2);
    formData.append("Line3", values.Line3);
    formData.append("Line4", values.Line4);
    formData.append("Line5", values.Line5);
    formData.append("CategoryId", values.Category.Id);
    formData.append("IsDonated", values.IsDonated.value);
    formData.append("Photo", values.File);

    const punDetailUpdate = {
      Name: values.Name,
      Description: values.Description,
      PublishDate: values?.PublishDate ? values?.PublishDate : null,
      DateSubmitted: values.DateSubmitted,
      Line1: values.Line1,
      Line2: values.Line2,
      Line3: values.Line3,
      Line4: values.Line4,
      Line5: values.Line5,
      IsDonated: values.IsDonated.value,
    };

    if (
      editRecordDetail?.editData &&
      Object.entries(editRecordDetail?.editData)?.length > 0
    ) {
      punDetailUpdate.Id = editRecordDetail?.editData?.Id;
      updatePun({
        entity: "Pun",
        query: `statusId=${values.PunStatus.Id}`,
        data: punDetailUpdate,
        tag: PUN_TAG,
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(false);
          if (response?.data?.value) {
            setAlreadyExistPun("updated")
          } else {
            successToast("Pun updated successfully.");
          }
        }
      });
    } else {
      addPun({
        entity: "Pun",
        data: formData,
        tag: PUN_TAG,
      }).then((response) => {
        if (!response.error) {
          setEditRecordDetail({
            editData: response.data,
            editStatusData: response.data,
          });
          setIsAddModalOpen(false);
          if (response?.data?.value) {
            setAlreadyExistPun("added")
          } else {
            successToast("Pun added successfully.");
          }
        }
      });
    }
  };
  return (
    <Formik
      initialValues={{
        Name:
          editRecordDetail && editRecordDetail?.editData?.Name
            ? editRecordDetail?.editData?.Name
            : "",
        Description:
          editRecordDetail && editRecordDetail?.editData?.Description
            ? editRecordDetail?.editData?.Description
            : "",
        PublishDate:
          editRecordDetail && editRecordDetail?.editData?.PublishDate
            ? editRecordDetail?.editData?.PublishDate.split("T")[0]
            : "",
        DateSubmitted:
          editRecordDetail && editRecordDetail?.editData?.DateSubmitted
            ? editRecordDetail?.editData?.DateSubmitted.split("T")[0]
            : "",
        Line1:
          editRecordDetail && editRecordDetail?.editData?.Line1
            ? editRecordDetail?.editData?.Line1
            : "",
        Line2:
          editRecordDetail && editRecordDetail?.editData?.Line2
            ? editRecordDetail.editData.Line2
            : "",
        Line3:
          editRecordDetail && editRecordDetail?.editData?.Line3
            ? editRecordDetail?.editData?.Line3
            : "",
        Line4:
          editRecordDetail && editRecordDetail?.editData?.Line4
            ? editRecordDetail.editData.Line4
            : "",
        Line5:
          editRecordDetail && editRecordDetail?.editData?.Line5
            ? editRecordDetail?.editData.Line5
            : "",
        File: "",
        Category: "",
        PunStatus:
          editRecordDetail?.editStatusData &&
          Object.entries(editRecordDetail?.editStatusData)?.length &&
          editRecordDetail?.editStatusData,
        IsDonated:
          editRecordDetail && Object.entries(editRecordDetail?.editData)?.length
            ? {
              label: editRecordDetail?.editData?.IsDonated ? "Yes" : "No",
              value: editRecordDetail?.editData?.IsDonated,
            }
            : "",
      }}
      onSubmit={onSubmit}
      validationSchema={punValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Pun Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <div className="mb-3">
              <Label for="IsDonated">
                Is Donation
                <font color="red"> *</font>
              </Label>
              <AsyncPaginate
                className={`lazySelect`}
                name="IsDonated"
                value={values.IsDonated}
                options={isDontedOptions}
                placeholder={`Select IsDonated`}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("IsDonated", e)}
              />
              {touched.IsDonated && errors.IsDonated && (
                <font color="red">{errors.IsDonated}</font>
              )}
            </div>
            {editRecordDetail?.editData &&
              Object.entries(editRecordDetail?.editData)?.length ? (
              ""
            ) : (
              <AsyncPaginates
                value={values.Category}
                label="Category"
                name="Category"
                entity="Category"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Category}
                fieldTouched={touched.Category}
                handleBlur={handleBlur}
                required
              />
            )}
            <CustomInput
              label="Submitted Date"
              name="DateSubmitted"
              type="date"
              fieldErrors={errors.DateSubmitted}
              fieldTouched={touched.DateSubmitted}
              fieldValue={values.DateSubmitted}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Publish Date"
              name="PublishDate"
              type="date"
              fieldErrors={errors.PublishDate}
              fieldTouched={touched.PublishDate}
              fieldValue={values.PublishDate}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Line 1"
              name="Line1"
              fieldErrors={errors.Line1}
              fieldTouched={touched.Line1}
              fieldValue={values.Line1}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Line 2"
              name="Line2"
              fieldErrors={errors.Line2}
              fieldTouched={touched.Line2}
              fieldValue={values.Line2}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Line 3"
              name="Line3"
              fieldErrors={errors.Line3}
              fieldTouched={touched.Line3}
              fieldValue={values.Line3}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Line 4"
              name="Line4"
              fieldErrors={errors.Line4}
              fieldTouched={touched.Line4}
              fieldValue={values.Line4}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Line 5"
              name="Line5"
              fieldErrors={errors.Line5}
              fieldTouched={touched.Line5}
              fieldValue={values.Line5}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {editRecordDetail?.editData &&
              Object.entries(editRecordDetail?.editData)?.length ? (
              <AsyncPaginates
                value={values.PunStatus}
                label="Pun Status"
                name="PunStatus"
                entity="Status"
                setFieldValue={setFieldValue}
                fieldErrors={errors.PunStatus}
                fieldTouched={touched.PunStatus}
                handleBlur={handleBlur}
              />
            ) : (
              ""
            )}
            {editRecordDetail?.editData &&
              Object.entries(editRecordDetail?.editData)?.length ? (
              ""
            ) : (
              <CustomInput
                label="File"
                name="File"
                type="file"
                handleChange={(e) => {
                  setFieldValue("File", e.target.files[0]);
                }}
                fieldErrors={errors.File}
                fieldTouched={touched.File}
                handleBlur={handleBlur}
              />
            )}
            <Button
              disabled={isPunAddLoading || isPunUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isPunAddLoading || isPunUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

PunsForm.propTypes = {
  editData: PropTypes.object,
  editStatusData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func,
};

export default PunsForm;
