import {
  themeReducer,
  rtlReducer,
  customizerReducer,
  sidebarReducer,
  authReducer,
  roundBordersReducer,
  blocksShadowsReducer,
} from "../../redux/reducers/index";
import appConfigReducer from "../../redux/reducers/appConfigReducer";

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { SignPunsApi } from "../../services/SignPunsApi";

export const store = configureStore({
  reducer: {
    [SignPunsApi.reducerPath]: SignPunsApi.reducer,
    theme: themeReducer,
    rtl: rtlReducer,
    roundBorders: roundBordersReducer,
    blocksShadows: blocksShadowsReducer,
    appConfig: appConfigReducer,
    customizer: customizerReducer,
    sidebar: sidebarReducer,
    user: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(SignPunsApi.middleware),
});

setupListeners(store.dispatch);

export default store;
