import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "reactstrap";
import Activity from "@/shared/components/entity_detail/Activity";
import AddModal from "@/shared/components/AddModal";
import Summary from "@/shared/components/entity_detail/Summary";
import { PUN_TAG } from "@/shared/tagFile";
import { useLazyGetRecordsQuery } from "@/services/SignPunsApi";
import Crosslinks from "./Crosslinks";
import PunsForm from "../Puns/PunsForm";
import "react-toastify/dist/ReactToastify.css";

const PunDetail = (props) => {
  const punId = JSON.parse(props.location.state.PunId);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [punDetail, setPunDetail] = useState();

  const [
    getPunRecords,
    {
      data: punList,
      isError: isPunError,
      isLoading: isPunLoading,
      isFetching: isPunFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    getPunRecords({
      entity: "PunStatus",
      filter: `Pun/Id eq ${punId}`,
      expand: {
        Pun: { expand: { PunMedias: { expand: { Media: {} } } } },
        Status: {},
      },
      tag: PUN_TAG,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isPunLoading && !isPunError && punList && !isPunFetching) {
      setPunDetail(punList.value[0]);
    }
    // eslint-disable-next-line
  }, [isPunError, punList, isPunLoading, isPunFetching]);
  return (
    <Container>
      {punDetail ? (
        <Row>
          <Summary
            displayData={punDetail.Pun}
            title="Pun"
            setIsAddModalOpen={setIsAddModalOpen}
            image={
              punDetail.Pun.PunMedias[punDetail.Pun.PunMedias?.length - 1]?.Media
                ?.CloudinaryAddress
            }
          ></Summary>
          <Activity></Activity>
          <Crosslinks displayData={punDetail}></Crosslinks>
          {isAddModalOpen && (
            <AddModal
              toggle={() => setIsAddModalOpen(!isAddModalOpen)}
              color="success"
              title="Edit Pun"
              header
              btn="Success"
              message={
                <PunsForm
                  editData={punDetail.Pun}
                  setIsAddModalOpen={setIsAddModalOpen}
                  editStatusData={punDetail.Status}
                />
              }
            />
          )}
        </Row>
      ) : (
        <Spinner size="sm" variant="light" />
      )}
    </Container>
  );
};

export default PunDetail;
