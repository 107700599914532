export const USER_TAG = "user_tag";
export const SITE_ROLE_TAG = "site_role_tag";
export const SITE_ROLE_TYPE_TAG = "site_role_type_tag";
// Pun
export const PUN_TAG = "pun_tag";
export const PUN_MEDIA_TAG = "pun_media_tag";
export const PUN_CATEGORY_TAG = "pun_category_tag";
export const PUN_USER_TAG = "pun_user_tag";

//Category
export const CATEGORY_TAG = "category__tag";
export const CATEGORY_MEDIA_TAG = "category_media_tag";

//Product
export const PRODUCT_TAG = "product_tag";
export const PRODUCT_CATEGORY_TAG = "product_category_tag";

export const TagType = [
  SITE_ROLE_TAG,
  SITE_ROLE_TYPE_TAG,
  USER_TAG,
  // Pun
  PUN_TAG,
  PUN_MEDIA_TAG,
  PUN_CATEGORY_TAG,
  PUN_USER_TAG,

  //Category
  CATEGORY_TAG,
  CATEGORY_MEDIA_TAG,

  //Product
  PRODUCT_TAG,
  PRODUCT_CATEGORY_TAG,
];
