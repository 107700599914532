import React from "react";
import { useHistory } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap";
import EditIcon from "mdi-react/PencilIcon";
import LeftIcon from "mdi-react/KeyboardArrowLeftIcon";
import noImageAvailable from "@/assets/Images/no-image.jpg";

const Summary = ({
  displayData,
  title,
  setIsAddModalOpen,
  restContent,
  image = "",
}) => {
  const history = useHistory();
  return (
    <>
      <Col xl={3}>
        <Card className="vh-90">
          <CardBody>
            <div className="card__title">
              <div
                className="bold-text cursor-pointer text-primary"
                onClick={() => {
                  history.goBack()
                }}
              >
                <LeftIcon />
                {title}
              </div>
            </div>
            <div className="tabs tabs--bordered-bottom">
              <div className="d-flex">
                <div className="profile__avatar">
                  <img src={image ? image : noImageAvailable} alt="avatar" />
                </div>
                <div className="profile__data">
                  <p className="profile__name">{displayData?.Name}</p>
                  <p className="profile__work">
                    {displayData[`${title}Type`] &&
                      displayData[`${title}Type`].Name}
                  </p>
                  <p className="profile__contact">{displayData?.Description}</p>
                  <div className="d-flex justify-content-center mt-5">
                    <div className="">
                      <div className="profile__name ">
                        {displayData && displayData?.Line1
                          ? displayData.Line1
                          : null}
                      </div>
                      <div className="profile__name">
                        {displayData && displayData?.Line2
                          ? displayData.Line2
                          : null}
                      </div>
                      <div className="profile__name">
                        {displayData && displayData?.Line3
                          ? displayData.Line3
                          : null}
                      </div>
                      <div className="profile__name">
                        {displayData && displayData?.Line4
                          ? displayData.Line4
                          : null}
                      </div>
                      <div className="profile__name">
                        {displayData && displayData?.Line5
                          ? displayData.Line5
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
                {setIsAddModalOpen && (
                  <div className="cursor-pointer">
                    <EditIcon onClick={() => setIsAddModalOpen(true)} />
                  </div>
                )}
              </div>
            </div>
            {restContent && restContent}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Summary;
