import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import { PRODUCT_CATEGORY_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { useAddMutation, useUpdateMutation } from "@/services/SignPunsApi";

const ProductCategoryForm = ({ editData, setIsAddModalOpen }) => {
  const [addProductCategory, { isLoading: isProductCategoryAddLoading }] =
    useAddMutation();
  const [updateProductCategory, { isLoading: isProductCategoryUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData ? editData : null
  );

  const productCategoryValidationSchema = Yup.object().shape({
    Product: Yup.mixed().required("Please select Product."),
    Category: Yup.mixed().required("Please select Category."),
  });

  const onSubmit = (values) => {
    const ProductCategoryDetail = {
      ProductId: values.Product.Id,
      CategoryId: values.Category.Id,
    };
    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      ProductCategoryDetail.Id = editRecordDetail.Id;
      updateProductCategory({
        entity: "ProductCategory",
        data: ProductCategoryDetail,
        tag: PRODUCT_CATEGORY_TAG,
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(false);
          successToast("Product-Category updated successfully.");
        }
      });
    } else {
      addProductCategory({
        entity: "ProductCategory",
        data: ProductCategoryDetail,
        tag: PRODUCT_CATEGORY_TAG,
      }).then((response) => {
        if (!response.error) {
          setEditRecordDetail(response.data);
          setIsAddModalOpen(false);
          successToast("Product-Category added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Product:
          editRecordDetail && editRecordDetail.Product
            ? editRecordDetail.Product
            : "",
        Category:
          editRecordDetail && editRecordDetail.Category
            ? editRecordDetail.Category
            : "",
      }}
      onSubmit={onSubmit}
      validationSchema={productCategoryValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <AsyncPaginates
              value={values.Product}
              label="Product"
              name="Product"
              entity="Product"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Product}
              fieldTouched={touched.Product}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.Category}
              label="Category"
              name="Category"
              entity="Category"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Category}
              fieldTouched={touched.Category}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={
                isProductCategoryAddLoading || isProductCategoryUpdateLoading
              }
              onClick={handleSubmit}
              classProduct="modal_ok float-right"
              color="success"
            >
              {(isProductCategoryAddLoading ||
                isProductCategoryUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ProductCategoryForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func,
};

export default ProductCategoryForm;
