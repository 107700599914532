import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "reactstrap";
import { RTLProps } from "../../shared/prop-types/ReducerProps";

const AddModal = ({ color, title, message, rtl, toggle, xl, testid }) => {
  return (
    <div data-testid={testid}>
      <Modal
        isOpen={true}
        toggle={toggle}
        modalClassName={rtl && `${rtl.direction}-support`}
        className={`modal-dialog--${color} ${
          xl ? "modal-dialog--header-xl" : "modal-dialog--header"
        } modal-dialog-box theme-light`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <div className="modal__body">{message}</div>
      </Modal>
    </div>
  );
};

AddModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.object,
  color: PropTypes.string.isRequired,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
  xl: PropTypes.bool,
};

AddModal.defaultProps = {
  title: "",
  message: {},
  colored: false,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(AddModal);
